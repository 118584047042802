/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Import global styles
import 'normalize.css';
/* FIXME Remove uikit js library */
import 'uikit/dist/js/uikit.min';
import 'uikit/dist/css/uikit.min.css';

import 'typeface-lato';
import 'typeface-handlee';
